.login-container, .signup-container {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .login-container form, .signup-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container input, .signup-container input {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .login-container button, .signup-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .error {
    color: red;
  }